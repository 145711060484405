<template>
<div class="shop-giftcards clamped">
    <div class="shop-section clamped grid">
        <template v-for="(giftcard, idx) in giftcards" :key="`card-${idx}`">
            <div class="product-tile flex-column">
                <ProductTile :product="giftcard" :collection="'giftcards'" :background-img="giftcard.featuredImage?.url" />
                <p class="product-title">{{ giftcard.title }}</p>
                <div class="pricing flex-row">
                    <ShopPrice :key="shopPriceKey" :product="giftcard" />
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script setup lang="ts">
import type { Shopify } from '$services/shopify'
import ProductTile from '$components/shopping/ProductTile.vue'
import ShopPrice from '$components/shopping/ShopPrice.vue'
import shopifyCollections from '$components/shopping/shopifyCollections.json'
import currencyCodeCloudfrontRegionMap from '$components/shopping/currencyCodeCloudfrontRegionMap.json'
import { currentCountry } from '$services/shopify'

const { locale } = useI18n()
const app = useNuxtApp()
const giftcards: Ref<Shopify.CollectionProduct[] | null> = ref(null)
const shopPriceKey = ref(0)

const { data: giftcardCollection } = await useAsyncData<Shopify.Collection>(
    `shop-gift-cards-${locale.value}`,
    async () => {
        const giftcardCollectionId = shopifyCollections.GIFTCARDS

        try {
            // fetch products from shopify
            const giftcardCollection = await app.$shopify.fetchCollection(giftcardCollectionId)
            return giftcardCollection
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)
giftcards.value = giftcardCollection.value.products

const shouldReload = computed(() => {
    let res = false
    // check what currency is present from the fetched data and compare to geolocation cookie
    if (giftcards.value[0].priceRange.maxVariantPrice.currencyCode) {
        const cc = giftcards.value[0].priceRange.maxVariantPrice.currencyCode
        const doesMatch = currencyCodeCloudfrontRegionMap[cc].includes(currentCountry.code)
        if (!doesMatch) res = true
    }
    return res
})

watch(shouldReload, async () => {
    if (shouldReload.value === true) {
        await refreshNuxtData(`shop-gift-cards-${locale.value}`)
        giftcards.value = giftcardCollection.value.products
        // increment component key to ensure currency updates
        shopPriceKey.value += 1
    }
}, { immediate: true })
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.shop-giftcards {
    margin-bottom: 40px;
    justify-self: center;

    .shop-section {
        @include mq-medium {
            margin: 0px 40px;
        }

        @include mq-small-and-less {
            margin: 0px 16px;
        }

        .product-tile {
            .steam-code {
                margin: 12px 0px 4px 0px;
                width: 90px;
            }

            .pricing {
                padding-top: 6px;

                @include mq-xsmall {
                    padding: 8px 0px 24px 0px;
                }

                .price {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 16px;
                }
            }

            .product-title {
                color: var(--neutral-100);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-top: 12px;
                margin-bottom: 0px;
            }

            :deep(.product-img) {
                aspect-ratio: 305/186;
                @include mq-medium {
                    aspect-ratio: 282/172;
                }
                @include mq-small {
                    aspect-ratio: 296/180.5;
                }
                @include mq-xsmall {
                    aspect-ratio: 350/213;
                    width: 100%;
                }
            }
        }
    }

    .shop-section-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        margin-left: 20px;
    }
}

/* grid layout */
.grid {
    .shop-section {
        max-width: 1280px;

        /* 4 cols > 959px */
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 40px;

        /* 3 cols > 599 */
        @include mq-small {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 16px;
        }

        /* 1 col < 600 */
        @include mq-xsmall {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 0px;
        }
    }
}
</style>
