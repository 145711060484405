<template>
<div class="tab-selector-container">
    <div class="shop-tab-selector">
        <NuxtLink
            v-for="shopTab in shopTabs" :key="shopTab.id" class="tab"
            :aria="shopTab"
            :class="{active: shopTab.id === activeTab}"
            :to="setActiveTab(shopTab.id)"
        >
            {{ shopTab.label }}
        </NuxtLink>
    </div>
</div>
</template>

<script lang="ts">
export default defineNuxtComponent({
    props: {
        activeTab: String
    },

    data() {
        return {
            shopTabs: [
                { id: 'games', label: this.$t('NAVBAR.GAMES'), slug: 'games' },
                { id: 'merch', label: this.$t('SHOP.MERCH'), slug: 'merch' },
                { id: 'giftcards', label: this.$t('SHOP.GIFT_CARDS'), slug: 'giftcards' }
            ]
        }
    },
    methods: {
        setActiveTab(shopTabId: string) {
            const shopTab = this.shopTabs.find((tab) => tab.id === shopTabId)
            if (shopTab) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return this.localeRoute({
                    name: 'shop-tab',
                    params: {
                        tab: shopTab.slug
                    }
                })
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@import '../../styles/kit.scss';
    .shop-tab-selector {
        background: #242838;
        padding: 20px 60px;
        margin-bottom: 40px;
        width: 100%;

        .tab {
            background: none;
            border: none;
            color: var(--neutral-300);
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            outline: none;
            padding: 20px;
            text-transform: uppercase;
            text-align: center;

            &.active {
                color: var(--neutral-50);
            }
        }

        @include mq-medium {
            padding: 20px;
        }

        /* mobile styling*/
        @include mq-small-and-less {
            background: #0C0D12;
            border: none;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 4px;
            max-width: 100vw;

            .tab {
                border-radius: 3px;
                width: 100%;
                padding: 16px 8px;

                &.active {
                    background: #353B52;
                    font-size: 13px;
                    font-weight: 800;
                    line-height: 13px;
                }
            }
        }
    }

    .tab-selector-container {
        @include mq-small-and-less {
            padding: 0px 16px;
            margin-top: 16px;
        }
    }

</style>
