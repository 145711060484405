<template>
<!--eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
<div
    class="product-tile" tabindex="0" :aria="product.title"
    v-on:mouseenter="showCartIcon(product.handle)"
    v-on:focusin="showCartIcon(product.handle)" v-on:mouseleave="hideCartIcon(product.handle)"
    v-on:focusout="hideCartIcon(product.handle)"
>
    <NuxtLink
        :id="`gradient-${product.handle}`" class="gradient"
        :to="navigateToDetailPage(product)"
    >
        <div>
            <NuxtImg
                v-if="backgroundImg"
                :src="`${backgroundImg}?format=auto&width=360`" alt=""
                class="product-img"
                aria-hidden
                v-on:error="imageLoadError"
            />
            <NuxtImg
                v-else class="product-img fallback" alt=""
                aria-hidden
                src="/images/characters-bg.jpg"
            />
        </div>
    </NuxtLink>
    <p :id="`tooltip-${product.handle}`" :class="`tooltip`">{{ $t('PACK_DETAIL.ADD_TO_CART') }}</p>
    <button
        :id="`cart-icon-${product.handle}`" :aria="$t('PACK_DETAIL.ADD_TO_CART') " class="add-to-cart-icon"
        v-on:click="addToCart(product)" v-on:keydown.enter="addToCart(product)"
        v-on:mouseenter="showTooltip(product.handle)" v-on:focusin="showTooltip(product.handle)"
        v-on:mouseleave="hideTooltip(product.handle)" v-on:focusout="hideTooltip(product.handle)"
    >
        <span>
            <i :id="`svg-${product.handle}`" :class="cartIconClasses" />
        </span>
    </button>
</div>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import Shopify from '@jackboxgames/shopify'
import { ShoppingCart } from '$services/shopify'
import type { JBGShopify } from '$services/shopify'

export default defineNuxtComponent({
    props: {
        product: {
            type: Object as PropType<JBGShopify.CollectionProduct>,
            required: true
        },
        collection: {
            type: String,
            required: true
        },
        backgroundImg: {
            type: String,
            required: false
        }
    },

    computed: {
        cartIconClasses() {
            return 'fa-solid fa-cart-plus'
        }
    },

    methods: {
        async addToCart(product: JBGShopify.CollectionProduct) {
            const variantID = product.variants[0].id
            if (!variantID) console.error('couldn\'t get variant id')

            // create a line item
            const lineItem: Shopify.Shopify.LineItem = {
                merchandiseId: variantID,
                quantity: 1,
                attributes: []
            }

            try {
                await this.$shopify.addItemToCart(lineItem, 1)
                ShoppingCart.open()
                // TODO: analytics
            } catch (error) {
                console.error('Failed to add item to cart:', error.message)
            }
        },

        showCartIcon(productHandle: string) {
            if (window.innerWidth < 960) return
            document.getElementById(`cart-icon-${productHandle}`).style.visibility = 'visible'
        },

        hideCartIcon(productHandle: string) {
            if (window.innerWidth < 960) return
            document.getElementById(`cart-icon-${productHandle}`).style.visibility = 'hidden'
        },

        showTooltip(productHandle: string) {
            if (window.innerWidth < 960) return
            document.getElementById(`tooltip-${productHandle}`).style.visibility = 'visible'
            document.getElementById(`gradient-${productHandle}`).classList.add('darken')
        },

        hideTooltip(productHandle: string) {
            if (window.innerWidth < 960) return
            document.getElementById(`tooltip-${productHandle}`).style.visibility = 'hidden'
            document.getElementById(`gradient-${productHandle}`).classList.remove('darken')
        },

        imageLoadError(e: Event) {
            // set a fallback image
            const el = e.target as HTMLImageElement
            el.src = '/images/characters-bg.jpg'
            el.classList.add('fallback')
        },

        navigateToDetailPage(product: JBGShopify.CollectionProduct) {
            if (!product) return

            const name = this.getRouteName(this.collection)

            if (name === 'shop') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return this.localeRoute({
                    name
                })
            }

            if (name === 'shop-merch-merchslug') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return this.localeRoute({
                    name,
                    params: {
                        merchslug: product.handle
                    }
                })
            }

            let slug = product.handle
            // fix for the naming discrepency of standalone fibbage
            if (slug === 'fibbage-xl') {
                slug = 'fibbage'
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return this.localeRoute({
                name,
                params: {
                    // Nuxt will drop invalid params and we could be
                    // going to any product detail page kind, so (for now)
                    // just pass them all to the router.
                    packslug: slug,
                    bundleslug: slug,
                    gameslug: slug
                }
            })
        },

        /**
         * Leaving this here for now for illustrative purposes. When we
         * have time for improvements, we should revisit our routing patterns.
         */
        navigateToDetailPageAlt(product: JBGShopify.CollectionProduct) {
            if (!product) return

            let nextSlug = product.handle

            switch (this.collection) {
            case 'packs':
                // A fix for the weirdness of PP1.
                if (nextSlug === 'the-jackbox-party-pack') {
                    nextSlug = 'the-jackbox-party-pack-1'
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    name: 'games-pack-packslug',
                    params: {
                        packslug: nextSlug
                    }
                }))
            case 'bundles':
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    name: 'games-bundles-bundleslug',
                    params: {
                        bundleslug: nextSlug
                    }
                }))
            case 'standalones':
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    name: 'games-gameslug',
                    params: {
                        gameslug: nextSlug
                    }
                }))
            case 'giftcards':
            case 'merch':
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    name: 'shop-merch-merchslug',
                    params: {
                        merchslug: nextSlug
                    }
                }))
            default:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    name: 'shop'
                }))
            }
        },

        getRouteName(collection: string): string {
            switch (collection) {
            case 'packs':
                return 'games-packs-packslug'
            case 'bundles':
                return 'games-bundles-bundleslug'
            case 'standalones':
                return 'games-gameslug'
            case 'giftcards':
            case 'merch':
                return 'shop-merch-merchslug'
            default:
                return 'shop'
            }
        }
    }
})

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.product-tile {
    position: relative;
    margin-bottom: -34px;
    width: 100%;

    @include mq-xsmall {
        margin-bottom: -28px;
    }

    .product-img {
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 12px;
        max-width: 100%;
        height: auto;

        &:hover {
            cursor: pointer;
            outline: solid 3px var(--primary-50);
            outline-offset: -1.5px;
        }

        &.fallback {
            filter: brightness(40%);
        }
    }

    .add-to-cart-icon {
        outline: none;
        border: none;
        background: none;
        visibility: hidden;

        @include mq-small-and-less {
            visibility: visible;
        }

        &:hover {
            cursor: pointer;
        }

        svg {
            color: var(--neutral-50);
            position: absolute;
            top: 0;
            right: 0;

            width: 26.897px;
            height: 24px;
            padding: 14px;

            @include mq-small-and-less {
                border-radius: 100px;
                background: rgba(0, 0, 0, 0.48);
                padding: 10px;
                width: 20px;
                height: 20px;
                top: 12px;
                right: 12px;
            }
        }
    }

    .tooltip {
        background: var(--neutral-50);
        border-radius: 2px;
        color: var(--surface-900);
        visibility: hidden;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        padding: 8px;
        position: absolute;
        top: -32px;
        right: -20px;
        width: fit-content;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 41px;
            width: 0;
            border-top: 8px solid var(--neutral-50);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
    }

    .gradient {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.darken {
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.16) 100%);
        }

        &:hover {
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0.00) 15.43%);
        }

        @include mq-small-and-less {
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0.00) 15.43%);
        }
    }
}

</style>
