<template>
<div class="shop-games">
    <h1 class="shop-section-header">PARTY PACKS</h1>
    <div class="shop-section clamped packs grid">
        <template v-for="(pack, idx) in packs" :key="`pack-${idx}`">
            <div class="product-tile pack flex-column">
                <ProductTile :product="pack" :collection="'packs'" :background-img="getPackBackgroundImg(pack)" />
                <NuxtImg class="steam-code" :alt="$t('SHOPPING_CART.STEAM_CODE_ALT')" src="/images/steam-code.svg" />
                <p class="title">{{ pack.title }}</p>
                <div class="pricing flex-row">
                    <ShopPrice :key="shopPriceKey" :product="pack.variants[0]" />
                </div>
            </div>
        </template>
    </div>
    <h1 class="shop-section-header">BUNDLES</h1>
    <div class="shop-section clamped bundles grid">
        <template v-for="(bundle, idx) in bundles" :key="`bundle-${idx}`">
            <div class="product-tile bundle flex-column">
                <ProductTile
                    :product="bundle" :collection="'bundles'"
                    :background-img="getBundleBackgroundImg(bundle) "
                />
                <NuxtImg class="steam-code" :alt="$t('SHOPPING_CART.STEAM_CODE_ALT')" src="/images/steam-code.svg" />
                <p class="title">{{ bundle.title }}</p>
                <div class="pricing flex-row">
                    <ShopPrice :key="shopPriceKey" :product="bundle.variants[0]" />
                </div>
            </div>
        </template>
    </div>
    <h1 class="shop-section-header">STANDALONES</h1>
    <div
        class="shop-section clamped standalones grid"
    >
        <template v-for="(standalone, idx) in standalones" :key="`standalone-${idx}`">
            <div class="product-tile standalone flex-column">
                <ProductTile
                    :product="standalone" :collection="'standalones'"
                    :background-img="getStandaloneBackgroundImg(standalone)"
                />
                <NuxtImg class="steam-code" :alt="$t('SHOPPING_CART.STEAM_CODE_ALT')" src="/images/steam-code.svg" />
                <p class="title">{{ standalone.title }}</p>
                <div class="pricing flex-row">
                    <ShopPrice :key="shopPriceKey" :product="standalone.variants[0]" />
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script setup lang="ts">
import type { Shopify } from '$services/shopify'
import ProductTile from '$components/shopping/ProductTile.vue'
import shopifyCollections from '$components/shopping/shopifyCollections.json'
import type { JBGWeb } from '$types/JBGWeb'
import ShopPrice from '$components/shopping/ShopPrice.vue'
import currencyCodeCloudfrontRegionMap from '$components/shopping/currencyCodeCloudfrontRegionMap.json'
import { currentCountry } from '$services/shopify'

const { locale } = useI18n()
const app = useNuxtApp()
const packs: Ref<Shopify.CollectionProduct[] | null> = ref(null)
const bundles: Ref<Shopify.CollectionProduct[] | null> = ref(null)
const standalones: Ref<Shopify.CollectionProduct[] | null> = ref(null)
const packsFromCMS: Ref<JBGWeb.PackDetail[] | null> = ref(null)
const bundlesFromCMS: Ref<JBGWeb.BundleDetail[] | null> = ref(null)
const standalonesFromCMS: Ref<JBGWeb.GameDetail[] | null> = ref(null)
const shopPriceKey = ref(0)

interface DigitalProducts {
    packCollection: Shopify.Collection
    bundleCollection: Shopify.Collection
    standaloneCollection: Shopify.Collection

    packsCMS: JBGWeb.PackDetail[]
    bundlesCMS: JBGWeb.BundleDetail[]
    standalonesCMS: JBGWeb.GameDetail[]
}

const { data: digitalProductsCollection } = await useAsyncData<Shopify.Collection>(
    `shop-games-${locale.value}`,
    async () => {
        const productCollections = <DigitalProducts>{}

        const packCollectionId = shopifyCollections.PACKS
        const bundleCollectionId = shopifyCollections.BUNDLES
        const standaloneCollectionId = shopifyCollections.STANDALONES

        try {
            // fetch game code products from shopify
            const packCollection = await app.$shopify.fetchCollection(packCollectionId)
            const bundleCollection = await app.$shopify.fetchCollection(bundleCollectionId)
            const standaloneCollection = await app.$shopify.fetchCollection(standaloneCollectionId)

            productCollections.packCollection = packCollection.products
            productCollections.bundleCollection = bundleCollection.products
            productCollections.standaloneCollection = standaloneCollection.products

            // fetch strapi media
            const CMSPacks: JBGWeb.PackDetail[] = await app.$cms.fetchMany('pack-details', { populate: ['tile'], locale: 'en' })
            productCollections.packsCMS = CMSPacks
            const CMSBundles: JBGWeb.BundleDetail[] = await app.$cms.fetchMany('bundle-details', { populate: ['tile'], locale: 'en' })
            productCollections.bundlesCMS = CMSBundles
            const CMSGames: JBGWeb.GameDetail[] = await app.$cms.fetchMany('game-details', { populate: ['tile', 'productTile', 'packDetails'], locale: 'en' })
            const CMSStandalones = CMSGames.filter((standalone) => !standalone.packDetails?.data)
            productCollections.standalonesCMS = CMSStandalones

            return productCollections
        } catch (error) {
            console.error('Failed to fetch shop data:', error.message)
            return productCollections
        }
    }
)

packs.value = digitalProductsCollection.value.packCollection
bundles.value = digitalProductsCollection.value.bundleCollection
standalones.value = digitalProductsCollection.value.standaloneCollection

packsFromCMS.value = digitalProductsCollection.value.packsCMS
bundlesFromCMS.value = digitalProductsCollection.value.bundlesCMS
standalonesFromCMS.value = digitalProductsCollection.value.standalonesCMS

const getPackBackgroundImg = (product: Shopify.CollectionProduct): string | undefined => {
    if (!packsFromCMS.value) return undefined
    const cmsProduct = packsFromCMS.value.find((p) => product.handle === p.slug)
    if (cmsProduct) {
        return cmsProduct.tile?.data?.attributes.url
    }
    return undefined
}

const getBundleBackgroundImg = (product: Shopify.CollectionProduct): string | undefined => {
    if (!bundlesFromCMS.value) return undefined
    const cmsProduct = bundlesFromCMS.value.find((p) => product.handle === p.slug)
    if (cmsProduct) {
        return cmsProduct.tile?.data?.attributes.url
    }
    return undefined
}

const getStandaloneBackgroundImg = (product: Shopify.CollectionProduct): string | undefined => {
    if (!standalonesFromCMS.value) return undefined
    let cmsProduct = standalonesFromCMS.value.find((p) => product.handle === p.slug)
    // fibbage naming discrepency workaround
    if (product.handle === 'fibbage-xl') {
        cmsProduct = standalonesFromCMS.value.find((p) => p.slug === 'fibbage')
    }
    if (cmsProduct) {
        if (!cmsProduct.productTile) return cmsProduct.tile?.data?.attributes.url
        return cmsProduct.productTile?.data?.attributes.url
    }
    return undefined
}

const shouldReload = computed(() => {
    let res = false
    // check what currency is present from the fetched data and compare to geolocation cookie
    if (packs.value[0].priceRange.maxVariantPrice.currencyCode) {
        const cc = packs.value[0].priceRange.maxVariantPrice.currencyCode
        const doesMatch = currencyCodeCloudfrontRegionMap[cc].includes(currentCountry.code)
        if (!doesMatch) res = true
    }
    return res
})

watch(shouldReload, async () => {
    if (shouldReload.value === true) {
        await refreshNuxtData(`shop-games-${locale.value}`)
        // increment component key to ensure currency updates
        shopPriceKey.value += 1
    }
}, { immediate: true })
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.shop-games {
    margin-bottom: 40px;
    justify-self: center;

    .shop-section-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0px 80px 16px 80px;

        &:not(:first-child) {
            margin-top: 38px;
        }

        @include mq-medium {
            margin: 0px 40px 16px 40px;
        }

        @include mq-small-and-less {
            margin: 0px 16px 16px 16px;
        }
    }

    .shop-section {
        margin: 0px 80px;
        @include mq-medium {
            margin: 0px 40px;
        }
        @include mq-small-and-less {
            margin: 0px 16px;
        }

        .product-tile {
            scroll-snap-align: center;

            .steam-code {
                margin: 12px 0px 4px 0px;
                width: 90px;
            }

            .pricing {
                padding: 6px 0px 12px 0px;

                .price {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 16px;
                }
            }

            .title {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0px;
            }

            &.pack {
                :deep(.product-img) {
                    aspect-ratio: 240/350;

                    @include mq-medium {
                        aspect-ratio: 284/414;
                    }

                    @include mq-small {
                        aspect-ratio: 299/438;
                    }

                    @include mq-xsmall {
                        aspect-ratio: 376/551;
                    }
                }
            }

            &.bundle {
                :deep(.product-img) {
                    aspect-ratio: 305/200;
                    @include mq-medium {
                        aspect-ratio: 384/250;
                    }
                    @include mq-small {
                        aspect-ratio: 456/297;
                    }
                    @include mq-xsmall {
                        aspect-ratio: 422/275;
                    }
                }
            }

            &.standalone {
                :deep(.product-img) {
                    aspect-ratio: 1/1;
                }
            }
        }

        &.grid {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 20px;
            row-gap: 30px;

            &.bundles {
                grid-template-columns: repeat(4, 1fr);
                @include mq-medium {
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 16px;
                    max-width: 1200px;
                }
                @include mq-small {
                    grid-template-columns: repeat(2, 1fr);
                    max-width: 929px;
                }
                @include mq-xsmall {
                    grid-template-columns: repeat(2, 1fr);
                    max-width: 500px;
                }
            }

            /* 4 cols > 959px */
            @include mq-medium {
                grid-template-columns: repeat(4, 1fr);
                column-gap: 16px;
                max-width: 1200px;
            }

            /* 3 cols > 599 */
            @include mq-small {
                grid-template-columns: repeat(3, 1fr);
                column-gap: 12px;
                max-width: 929px;
            }

            @include mq-xsmall {
                grid-template-columns: 1fr 1fr;
                column-gap: 12px;
            }

            .shop-section-header {
                grid-column: 1/-1; /* span all columns */
            }
        }
    }
}


</style>
